import { ModalBase } from "./modal_base";

class ConfirmModal extends ModalBase {
    constructor() {
        super('confirm_modal');
    }

    setupModalView($modalTriggerBtn, additionalData) {
        if(!additionalData || !additionalData.title || !additionalData.confirmMessage) {
            throw Error('Invalid confirm modal data provided.');
        }

        this.MODAL_CONTAINER_EL.find('.modal__title .title__content').text(additionalData.title);
        this.MODAL_CONTAINER_EL.find('.modal__content').html(additionalData.confirmMessage);
    }
}

window.addEventListener('ModalCoreSetup', () => {
    new ConfirmModal();
});